import React, { Component } from 'react'
import classnames from "classnames"
import CountUp from 'react-countup'
import { Waypoint } from 'react-waypoint'
import PathUtils from '../../classes/PathUtils'
import './FeatureNumberItems.scss'

class BoldNumberItem extends Component
{
	constructor(props)
	{
		super(props)
		this.state = {
			startCounting: false
		}
		this.countUpRef = React.createRef()
	}

	handleWaypointEnter = () => {
		this.setState({ startCounting: true })
	}

	render()
	{
		let pathUtils = new PathUtils()
		let data = this.props.data || {}

		let numberValue = data.number || 0
		let title = data.title || ""
		let subline = data.subline || null
		let icon_src = data.iconSrc || null
		let icon = ""

		if ( icon_src != null )
		{
			let iconSrc = pathUtils.resolveImagePath( icon_src )
			icon = <img className="icon" src={iconSrc} alt={""} />
		}

		return (
			<div className={classnames("BoldNumberItem")} data-scroll>
				{icon}
				<CountUp
					start={0}
					end={numberValue}
					delay={0}
					duration={1.0}
					separator="."
					decimals={0}
					decimal=","
					prefix=""
					suffix=""
					{...(this.state.startCounting ? {} : { start: false })}
				>
					{({ countUpRef }) => (
						<Waypoint
							onEnter={this.handleWaypointEnter}
							topOffset="100px"
							bottomOffset="100px"
						>
							<div ref={countUpRef} className="numValue" />
						</Waypoint>
					)}
				</CountUp>
				<div className="title" dangerouslySetInnerHTML={{ __html: title }} />
				{ subline != null
					? <div className="subline" dangerouslySetInnerHTML={{ __html: subline }} />
					: ""
				}
			</div>
		)
	}
}

export default BoldNumberItem